.home{
    background-color: #ffffff !important;
}

.features-container {
    width: 100%;
    padding: 20px;
}

.title-section {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-direction: column; /* 子元素垂直堆叠 */
    height: 100%;  /* 取决于父容器高度 */
}


.vertical-title {
    writing-mode: vertical-lr; /* 文字垂直显示，从左向右 */
    transform: rotate(360deg); /* 将文字旋转，使其从上到下 */
    text-align: center;
    color: #4ed385 !important;
    font-size: 60px !important;
    margin-top: 500px !important; /* 移除外边距，确保居中 */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.card-section {
    flex: 1 1 45%; /* 使卡片占据45%的宽度 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text-content {
    display: flex;
    flex-direction: column;
}


.feature-card {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    border: #f3fafe;
}

.feature-image {
    width: 240px !important;
    height: 140px !important;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 10px;
}

.feature-title {
    font-size: 16px !important;
    color: #499ae6 !important;
    text-align: left;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
}

.feature-description {
    text-align: left;
    font-size: 12px;
}

.feature-description-small {
    margin-top: 10px;
    text-align: left;
    font-size: 12px; /* 更小的字体大小 */
    color: #666; /* 较淡的文字颜色 */
}

.card-content {
    display: flex;
    align-items: center;
    padding: 0 !important;
}
