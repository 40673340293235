.info-banner {
    background-color: #FFD700;
    padding: 5px 20px; /* 减小垂直padding */
    width: 100%;
    display: flex;
    align-items: center; /* 确保所有内容垂直居中 */
    height: 100px; /* 减少横幅高度 */
    justify-content: flex-start; /* 设置为从左开始排列内容 */
}

.logo-img {
    border-radius: 1px;
    width: 200px; /* 调整LOGO宽度 */
    height: auto;
    flex-shrink: 0;
    margin-right: 120px; /* 增加与文本的间隔 */
}

.company-name {
    line-height: 0!important;
    margin-bottom: 0;
    margin-right: 10px; /* 添加右间距以分隔中英文名 */
    font-weight: bold;
    font-size: 12px; /* 减小字体大小以减少空间占用 */
    flex-grow: 1;
    text-align: left; /* 确保公司名称靠左对齐 */
}

.company-info-small {
    margin-bottom: 0;
    color: rgba(51, 51, 51, 0.76);
    display: block; /* 确保它在新行显示 */
    text-align: left; /* 确保小字体信息靠左对齐 */
    font-size: smaller;
}

.company-intro{
    line-height: 0!important;
}

.contact-info-right {
    margin-left: 85px;
    display: flex;
    line-height: 0;
    align-items: center;
    justify-content: flex-end; /* 确保联系信息在右边部分也靠左对齐 */
    flex-shrink: 0;
    text-align: left;
    width: 100%; /* 使得这部分内容能够扩展到整行的宽度 */
}

.phone-icon {
    font-size: 20px; /* 调整图标大小 */
    color: black;
    margin-right: 5px; /* 减小与电话号码的间隔 */
}

.contact-text {
    font-size: 10px; /* 调整字体大小 */
    font-weight: normal;
    margin-bottom: 0;
    line-height: 1; /* 减少行高以减小间距 */
    text-align: left;
}

.contact-title {
    font-size: 16px; /* 调整电话号码字体大小 */
    font-weight: bold;
    margin-top: 0 !important;
    text-align: right;
}
