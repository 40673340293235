.home-container {
    margin: 5px;
    padding: 5px;
}

.home-container .ant-card {
    margin-bottom: 5px;
}

.headerContainer {
    display: flex;
    justify-content: space-between; /* 确保内容分布在两端 */
    align-items: center; /* 垂直居中对齐 */
    width: 100%; /* 容器宽度100% */
    padding: 5px 5px; /* 略有内边距增加美观度 */
    border-bottom: 1px solid #ccc; /* 添加下划线 */
}


.headerTitle {
    font-size: 24px; /* 适当的字体大小 */
    font-weight: bold; /* 加粗字体 */
}

.headerArrow {
    cursor: pointer;
    font-size: 14px;
    color: #fff; /* 默认为白色图标 */
    background-color: black; /* 默认背景透明 */
    border-radius: 50%; /* 可选：圆形背景 */
    padding: 5px; /* 图标周围的填充 */
    transition: background-color 0.3s; /* 平滑过渡效果 */
}

.headerArrow:hover {
    background-color: #f9bf00;
}

.child-content {
    text-align: left;
    margin-top: 10px;
    height: 535px;
    overflow: hidden;
}

.ant-avatar {
    width: 60px;
    height: 60px;
}

.meta-content {
    font-size: 12px;
    text-align: left;
}

.ant-list-item-meta-title{
    font-size: 12px;
}

.ant-list-item-meta-description{
    font-size: 10px;
}

.company-build{
    height: 200px;
    width: 100%;
}
