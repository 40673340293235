.sidebar-outline {
    position: fixed;
    top: 35%;
    right: 10px;
    width: 200px; /* Adjust width as needed */
    background: rgba(255, 255, 255, 0.8); /* White with transparency */
    backdrop-filter: blur(10px); /* 毛玻璃效果 */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Light shadow for 3D effect */
    z-index: 1000; /* Ensure it's above other content */
}



.anchor-link-title {
    display: block;
    font-size: 16px; /* 较大字体大小 */
    color: #f9bf00; /* 链接颜色 */
    padding: 8px 16px; /* 链接内边距 */
    transition: background-color 0.3s; /* 平滑过渡效果 */
}

.ant-anchor-ink {
    background-color: #f9bf00 !important;
}
