.partner-img {
    width: 100%;  /* 设置宽度为父容器的100% */
    height: auto; /* 高度自动，以保持原始宽高比 */
    display: block; /* 使图片不留有空间 */
}

.slide-header{
    margin: 10px;
}

.slide-image {
    padding: 5px; /* 在每边添加2.5px的填充，总共5px间隙 */
}
