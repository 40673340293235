.headerContainer {
    display: flex;
    justify-content: space-between;  /* 确保内容分布在两端 */
    align-items: center;             /* 垂直居中对齐 */
    width: 100%;                     /* 容器宽度100% */
    padding: 10px 20px;              /* 略有内边距增加美观度 */
    border-bottom: 1px solid #ccc;   /* 添加下划线 */
    margin-top: 15px;
}


.headerTitle {
    font-size: 24px;                 /* 适当的字体大小 */
    font-weight: bold;               /* 加粗字体 */
}

.headerArrow {
    cursor: pointer;
    font-size: 14px;
    color: #fff; /* 默认为白色图标 */
    background-color: black; /* 默认背景透明 */
    border-radius: 50%; /* 可选：圆形背景 */
    padding: 5px; /* 图标周围的填充 */
    transition: background-color 0.3s; /* 平滑过渡效果 */
}

.headerArrow:hover {
    background-color: #f9bf00;
}
