.App {
    text-align: center;
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.App-header {
    width: 100%;
}

.logo1 {
    width: 100%;
    height: 100%;
}

.logo2 {
    /* margin-left: 10px; */
    width: 100% !important;
    height: 100% !important;
}

.whatsapp-button {
    position: relative;
    /* 设置相对定位，允许伪元素定位 */
    display: inline-block;
    /* 使按钮适应内容宽度 */
    overflow: hidden;
    /* 隐藏溢出的部分 */
}

.whatsapp-button img {
    width: 80%;
    margin-bottom: 20px;
    /* 在按钮和文本之间添加空间 */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    /* 添加平滑过渡效果 */
}

.whatsapp-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    /* 开始位置在左侧之外 */
    width: 150%;
    /* 覆盖整个按钮宽度 */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    /* 线性渐变模拟光影 */
    animation: sweep 2s linear infinite;
    /* 设置动画效果 */
    pointer-events: none;
    /* 防止干扰按钮交互 */
}

@keyframes sweep {
    0% {
        left: -150%;
    }

    100% {
        left: 100%;
    }
}

.whatsapp-button img:hover {
    transform: scale(1.05);
    /* 悬浮时放大 5% */
}


.contact-info .contact-row {
    display: flex;
    margin-left: 36px;
    align-items: center;
    /* 垂直居中对齐 */
    margin-bottom: 5px;
    /* 行间距 */
}

.contact-info .contact-row .icon {
    width: 24px;
    /* 图标大小 */
    height: 24px;
    /* 图标大小 */
    margin-right: 10px;
    /* 与文本间距 */
}

.contact-info .contact-text span {
    font-weight: bold;
    /* 加粗标签 */
    color: #333;
    /* 标签颜色 */
}

.contact-info .contact-text {
    font-size: 16px;
    /* 文本大小 */
    color: rgba(0, 0, 0, 0.94);
    /* 文本颜色 */
    text-align: left;
    /* 文本左对齐 */
}

.contact-text-l {
    font-size: 14px !important;
    font-weight: bold !important;
    ;
    color: #333333 !important;
    ;
}

.contact-text-r {
    font-weight: normal !important;
    margin-left: 10px !important;
    ;
    font-size: 16px !important;
    ;
    color: rgba(51, 51, 51, 0.77) !important;
    ;
}

.logo3 {
    width: 120px;
    height: 120px;
    margin-left: 20px;
}

.partOne {
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #eee;
}

.content1 {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #999;
}

/* 增加特定类以控制按钮顺序 */
.language-switch-btn {
    margin: 0 auto;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease-in-out;
    order: 1; /* 使“切换语言”按钮显示在最左侧 */
}

.language-switch-btn:hover {
    transform: scale(1.1);
}

.content1 .back-home-btn {
    order: 2; /* 使“返回首页”按钮显示在“切换语言”按钮的右侧 */
}

.content1 span {
    flex-grow: 1; /* 使问候语填充剩余空间 */
    text-align: left; /* 左对齐文本 */
}

.content1 .ant-btn {
    color: #999;
    font-size: 12px;
}

.partTwo {
    width: 100%;
    background-color: #fff;
}

.partTwo .content2 {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
}

.partThree {
    width: 100%;
    background-color: #f9bf00;
}

/* 导航栏基本样式 */
.navbar {
    /* transition: top 0.3s; */
    position: relative;
}

/* 吸顶样式 */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #f9bf00;
    /* 确保背景不透明 */
}


/* 隐藏导航栏的样式 */
.hidden {
    top: -100px;
    /* 根据实际导航栏高度调整 */
}

.partThree .navbar {
    width: 1200px;
    margin: 0 auto;
    transition: all 0.3s ease;
    /* 平滑过渡所有变化 */
}

.partThree .navbar ul {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.partThree .navbar ul li {
    flex-grow: 0;
    /* 不允许元素根据容器大小伸缩 */
    width: 200px;
    /* 根据实际需要调整 */
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* 主菜单项和子菜单标题鼠标悬停样式 */
.partThree .navbar .ant-menu-dark .ant-menu-item:hover,
.partThree .navbar .ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: rgba(235, 211, 211, 0.57);
}


.title-green-text {
    font-size: 40px;
    font-weight: lighter;
    color: #1ac562;
    /* 绿色 */
}

.title-gray-text {
    margin-top: 5px;
    font-size: 34px;
    font-weight: lighter;
    color: #333333;
    /* 深灰色 */
}

.text-sentence-cn {
    line-height: 30px;
    color: #333333;
    font-size: 14px;
    white-space: pre-wrap;
}

.text-sentence-en {
    line-height: 30px;
    color: rgba(51, 51, 51, 0.63);
    font-size: 14px;
    white-space: pre-wrap;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
    /* 增加一点内边距以避免文字紧贴边框 */
}

.menu-item-cn,
.menu-item-en {
    font-size: 22px;
    /* 如果需要，可以调小字体大小 */
    line-height: 40px;
    /* 减小行高可以使文字更紧凑 */
    color: rgba(255, 255, 255, 0.8);
}


.partThree .navbar .ant-menu-item,
.partThree .navbar .ant-menu-submenu-title {
    height: auto;
    /* 设置高度为自动以适应内容 */
    white-space: normal;
    /* 允许文字换行 */
    padding: 4px 20px;
    /* 调整内边距以适应更多内容 */
}

.ant-menu-submenu-popup {
    overflow: visible;
    /* 确保内容可以溢出 */
}

.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical-right,
.ant-menu-inline,
.ant-menu-sub {
    max-height: none;
    /* 移除可能的最大高度限制 */
}

/* 响应式媒体查询 */
@media (max-width: 1200px) {
    .content1 {
        width: 100%;
        /* 当屏幕宽度小于1200px时，宽度调整为100% */
    }

    .partTwo .content2 {
        width: 100%;
    }

    .partThree {
        width: 100%;
    }


    .partThree .navbar {
        width: 100%;
    }

}


.scroll-to-top {
    background-color: #ffb700;
    position: fixed;
    bottom: 120px;
    right: 20px;
    z-index: 1000;
    width: 50px!important; /* 自定义宽度 */
    height: 50px!important;; /* 自定义高度 */
    transition: transform 0.3s;
}

.scroll-to-top:hover {
    background-color: #f9d000 !important;
    transform: scale(1.2); /* 放大效果 */
}
