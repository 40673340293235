.slide-img {
    height: 1010px !important;
    width: 100% !important;
}

.carousel {
    width: 100%;
    height: 1010px;
}


.container {
    width: 1200px;
    margin: 20px auto;
}

.videoPlayer {
    margin-top: 15px;
}

.video-carousel .slick-slide {
    text-align: center;
    background: #364d79;
    /* 走马灯背景颜色 */
    overflow: hidden;
    /* 避免内容溢出 */
}

.video-carousel video {
    max-width: 100%;
    /* 确保视频不超过走马灯宽度 */
    display: block;
    /* 去除默认的内联布局 */
    margin: 0 auto;
    /* 视频居中显示 */
}

.industry,
.module {
    margin: 40px 0;
}

.module_img {
    height: 1300px !important;
    width: 100% !important;
}

.industry .industry-title,
.module .module-title,
.advantage .advantage-title {
    font-size: 34px;
    font-weight: 500;
    color: #45d17f;
    height: 110px;
    line-height: 110px;
}

.industry-img {
    height: 201px;
    width: 850px;
}

.about_img {
    height: 201px !important;
    width: 400px !important;
}

.ant-space {
    width: 100%;
}

.ant-space .textAlignLeft {
    text-align: left;
}

.ant-space .textAlignRight {
    text-align: right;
}

.project-name-bg,
.other-bg,
th.project-name-bg,
th.other-bg {
    background-color: #d4ecf2 !important;
}

.product-bg,
th.product-bg {
    background-color: #f5faf6 !important;
}

.home-about {
    margin: 60px 0;
    font-size: 14px;
}

.home-about .ant-divider .ant-divider-inner-text {
    font-size: 34px;
    color: #45d17f;
}

.home-about-content {
    margin: 20px 0;
    display: flex;
    text-align: left;
}

.company-content {
    margin-left: 20px;
    line-height: 30px;
    text-align: left;
}

.partners {
    margin-top: 5px;
}

.inside-info {
    margin-top: 25px;
}


@media (max-width: 1200px) {

    .container {
        width: 100%;
    }
}



/* Custom styles for Ant Design Carousel arrows */
.ant-carousel .slick-arrow {
    font-size: 24px; /* Larger arrow size */
    color: black; /* Arrow color for better visibility */
    opacity: 0.75; /* Partial transparency for style */
    z-index: 1; /* Ensure the arrows are above other content */
    width: 50px; /* Set a specific width for larger click area */
    height: 50px; /* Set a specific height for larger click area */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-carousel .slick-arrow:hover {
    color: black; /* Color on hover */
    opacity: 1; /* Full opacity on hover */
}

/* Position adjustments for left and right arrows */
.ant-carousel .slick-prev {
    left: 10px; /* Positioning from the left edge */
}

.ant-carousel .slick-next {
    right: 10px; /* Positioning from the right edge */
}

/* Specific styling to increase the visibility and size of the arrows */
.ant-carousel .slick-prev:before,
.ant-carousel .slick-next:before {
    font-size: 48px; /* Size of the arrow icons */
    color: #000; /* Arrow color */
}

/* 完全隐藏箭头按钮的 ::after 伪元素 */
.slick-next::after {
    content: none!important; /* 不显示任何内容 */
}

.slick-prev::after {
    content: none!important; /* 不显示任何内容 */
}

