.page-footer {
    background-color: #f9bf00;
    color: #fff;
    text-align: left;
    margin-top: 20px;
}

.page-footer .page-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.company {
    padding: 25px 30px;
    border-left: 1px solid #fff;
    margin-left: 30px;
}

.company .company-name {
    margin-bottom: 10px;
}

.company .company-name .cn {
    font-size: 16px;
    font-weight: 700;
}

.company .company-name .en {
    margin-top: 20px;
    font-size: 14px;
}

.company .company-address .cn {
    font-weight: 700;
}

.company .copyright {
    font-size: 14px;
    margin-top: 40px;
}

.QRcode-title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.footer_img {
    width: 100% !important;
    height: 100px !important;
}

.qrcode_img {
    width: 140px !important;
    height: 140px !important;
}

.text-bolder {
    font-weight: bolder;
}