.capacity-container {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    line-height: 30px;
    background-color: #fff;
}

.display-text {
    height: 100%;
}

.left-column {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
}

.text-card {
    text-align: left;
    width: 100%;
    /* 确保卡片宽度适应容器宽度 */
    border: none !important;
    /* 强制去除边框 */
    box-shadow: none !important;
    /* 移除阴影，如果有的话 */
}



.display-img {
    margin-top: 10px;
    height: 60%;
    /* 可根据实际情况调整高度 */
}

.full-img,
.stacked-img {
    width: 100%;
    margin-top: 10px;
    border-radius: 0.5dvh;
    height: 96.3333333%;
    /* 使图片完全填充容器 */
    object-fit: cover;
    /* 防止图片比例失调 */
}

.image-stack {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* 确保填满容器高度 */
}

.stacked-img {
    height: 161px;
    /* 每张图片占据1/3高度 */
}

.stacked-img:last-child {
    margin-bottom: 0;
}

@media (max-width: 1200px) {

    .capacity-container {
        width: 100%;
    }
}