html,
body,
#root {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

/* #root {
    display: flex;
    flex-direction: column;
} */

body {
    font-size: 12px;
    color: #333;
    background: #f3faff;
}

div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #fff;
}

div::-webkit-scrollbar-thumb {
    border: 1;
    border-radius: 10px;
    background-color: #b2b2b2;
}

div::-webkit-scrollbar-corner{
    border-radius: 4px;
}
