.header {
    width: 100%;
    background-color: #ccc;
    background-repeat: no-repeat;
}

.chooseSpreader {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    font-size: 16px;
    line-height: 1.6;
}

.page-title {
    font-weight: bolder;
    font-size: 40px;
    text-align: center;
    color: #333;
    margin-top: 50px;
}

.intro-eng {
    margin-top: 20px;
    text-align: left;
    font-size: 40px;
    color: #12b563;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.divider {
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-title-cn {
    color: #333;
    /* 为中文标题设置颜色 */
    font-size: 16px;
    /* 可根据需要调整 */
}

.card-title-en {
    color: rgba(51, 51, 51, 0.49);
    font-size: 16px;
    margin: 20px;
}

.sequence-text-content {
    white-space: pre-wrap;
}

.ant-card-hoverable:hover {
    background-color: rgba(249, 191, 0, 0.24);
    transition: .6s;
}

@media (max-width: 1200px) {

    .chooseSpreader {
        width: 100%;
    }
}

.anchor-target::before {
    content: '';
    display: block;
    height: 55px; /* Adjust this value to match your fixed header's height or required offset */
    margin-top: -55px; /* This should be the same as the height to counteract the added space */
    visibility: hidden;
}

.anchor-target {
    display: block; /* Ensures that the anchor targets behave correctly with the pseudo-element */
}
