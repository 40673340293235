.header-row{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}
.left-space {
    margin-left: 5px;
}

.eng-text {
    color: gray;
    font-size: small;
}

.model-column {
    background-color: #d6ecf2!important;
}

.x6-160-190-column {
    background-color: #f5faf6!important;
}

.x6-210-column {
    background-color: #e6f3f5!important;
}

.x6-260-310-column {
    background-color: #ddeff2 !important;
}
