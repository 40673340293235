.text-card {
    text-align: left;
    width: 100%; /* 确保卡片宽度适应容器宽度 */
    border: none !important; /* 强制去除边框 */
    box-shadow: none !important; /* 移除阴影，如果有的话 */
}

.productImg {
    width: 100%;
    height: 800px;
    border-radius: 0.5vh;
    object-fit: cover;
}

.custom-table {
    margin: 10px;
    width: 100%;
}
