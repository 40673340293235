.honor-container{
    line-height: 15px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.honor-text{
    font-weight: bolder;
    color: #333333;
}

.honor-text-eng{
    color: #707070;
    line-height: 20px;
}
