.contactContainer {
    max-width: 1200px;
    margin: 0px auto;
    min-height: 100vh;
    /* 确保视口高度 */
    background: #ffffff;
    /* 轻蓝色背景 */
}

.contentStyle {
    padding: 10px;
}

.cityImage {
    width: 100%;
    margin-top: px;
    height: 536px;
}

.infoRow .contactInfo {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* 添加阴影效果 */
}

.infoRow .contactInfo p {
    margin-bottom: 16px;
    /* 段落间距 */
}

.contactInfo {
    text-align: left;
    /* 确保文本居左对齐 */
    font-size: 14px;
    /* 设置较小的字体大小 */
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* 维持原有的阴影效果 */
}

.contactInfo p {
    display: flex;
    /* 使用 flexbox 布局 */
    flex-wrap: wrap;
    /* 允许内容自动换行 */
    align-items: baseline;
    /* 使标题和文本基线对齐 */
    margin-bottom: 16px;
    /* 保持原有的段落间距 */
}

.contactInfo h4 {
    margin-right: 8px;
    /* 在标题和随后文本之间添加空间 */
    white-space: nowrap;
    /* 防止标题内的文字换行 */
    color: #333;
    /* 保持原有的标题颜色 */
    font-size: 16px;
    /* 可以适当调整标题的字体大小 */
}


@media (max-width: 1200px) {

    .contactContainer {
        width: 100%;
    }
}