.advantage-container {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    line-height: 30px;
    background-color: #fff
}

.text-card {
    text-align: right;
    width: 100%;
    /* 确保卡片宽度适应容器宽度 */
    border: none !important;
    /* 强制去除边框 */
    box-shadow: none !important;
    /* 移除阴影，如果有的话 */
}

.advantage-table {
    margin: 16px;
}

@media (max-width: 1200px) {

    .advantage-container {
        width: 100%;
    }
}