.video-gallery {
    text-align: center;
}

.video-list-container {
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
}


.header {
    background-color: #fff;
}

.video-player {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    height: 60vh;
    background-color: #000;
    position: relative;
    /* background-image: url('https://xiaomai.tech/files/images/chejian.jpg'); */
    /* background-size: cover;
    background-position: center; */
}

.player-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.placeholder {
    color: #fff;
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    /* 毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);
    /* 兼容 Safari */
}



.fullscreen-button,
.exit-fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.video-card {
    width: 100% !important;
    height: 580px;
    margin: 5px auto;
    padding: 5px;
}

.video-card-img {
    width: 100%;
    height: 370px;
}

.description-en {
    margin-top: 5px;
    color: rgba(51, 51, 51, 0.57);
    font-size: 12px;
}



:where(.css-dev-only-do-not-override-1xxh14y).ant-card .ant-card-cover>* {
    display: block;
    width: 100%;
    border-radius: 0px 0px 0 0;
}

.video-card.selected {
    background-color: #f9bf00;
}


@media (max-width: 1200px) {

    .video-list-container {
        width: 100%;
    }

    .video-player {
        width: 100%;
    }
}


/* Custom styles for Ant Design Carousel arrows */
.ant-carousel .slick-arrow {
    font-size: 24px; /* Larger arrow size */
    color: black; /* Arrow color for better visibility */
    opacity: 0.75; /* Partial transparency for style */
    z-index: 1; /* Ensure the arrows are above other content */
    width: 50px; /* Set a specific width for larger click area */
    height: 50px; /* Set a specific height for larger click area */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-carousel .slick-arrow:hover {
    color: black; /* Color on hover */
    opacity: 1; /* Full opacity on hover */
}

/* Position adjustments for left and right arrows */
.ant-carousel .slick-prev {
    left: 10px; /* Positioning from the left edge */
}

.ant-carousel .slick-next {
    right: 10px; /* Positioning from the right edge */
}

/* Specific styling to increase the visibility and size of the arrows */
.ant-carousel .slick-prev:before,
.ant-carousel .slick-next:before {
    font-size: 48px; /* Size of the arrow icons */
    color: #000; /* Arrow color */
}

/* 完全隐藏箭头按钮的 ::after 伪元素 */
.slick-next::after {
    content: none!important; /* 不显示任何内容 */
}

.slick-prev::after {
    content: none!important; /* 不显示任何内容 */
}

