.intro-eng {
    font-size: 40px !important;
    color: #12b563!important;;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.about {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    font-size: 14px;
    line-height: 30px;
}

.divider {
    margin-top: 40px !important;
}

.divider .ant-divider-inner-text {
    font-size: 34px;
}

.aboutHeader {
    width: 100%;
    height: 600px;
    /* 或根据需要调整高度 */
    background-size: cover;
    background-position: center;
}

.spirit {
    display: flex;
    flex-direction: row;
}

.spirit-content {
    flex-grow: 1;
}

.spirit-process {
    width: 260px;
    margin-left: 20px;
    text-align: center;
}

.process {
    width: 260px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #45d17f;
    color: #fff;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.culture {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
}

.culture-item {
    flex: 1 1 calc(50% - 10px);
}

.culture-item .title {
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px solid #999;
    line-height: 34px;
}


@media (max-width: 1200px) {

    .about {
        width: 100%;
    }
}
